import Header from './components/Header';
import Features from './components/Features';
import Download from './components/Download';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Header />
      <div id="content">
        <Features />
        <Download />
        <Gallery />
        <Testimonials />
      </div>
      <Footer />
    </>
  );
}

export default App;
