import React from 'react';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-scroll';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link to="home" smooth>
              <button className="btn btn-link scrollup">
                <FontAwesomeIcon icon={faAngleUp} />
              </button>
            </Link>
            <p>All Rights Reserved - Innovative Software Technology, LLC - Copyright &copy; 2020</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
