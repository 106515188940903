import React, { useState } from 'react';

import iconEV from './images/help/icon_ev_charging.png';
import iconFuel from './images/help/icon_fuel.png';
import iconFood from './images/help/icon_food.png';
import iconOvernight from './images/help/icon_overnight.png';
import iconParking from './images/help/icon_parking.png';
import iconPet from './images/help/icon_pet_area.png';
import iconPicnic from './images/help/icon_picnic.png';
import iconPlayground from './images/help/icon_playground.png';
import iconRestrooms from './images/help/icon_restrooms.png';
import iconRV from './images/help/icon_rv_dump.png';
import iconSecurity from './images/help/icon_security.png';
import iconVending from './images/help/icon_vending.png';
import iconWater from './images/help/icon_water.png';
import iconWelcome from './images/help/icon_welcome.png';
import iconWifi from './images/help/icon_wifi.png';

function Help() {
  const [opened, setOpened] = useState<Array<number>>([1]);
  const style: React.CSSProperties = {
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    outline: 'inherit'
  };

  const handleToggle = (panelId: number) => {
    if (opened.includes(panelId)) {
      setOpened(opened.filter((p) => p !== panelId));
    } else {
      setOpened(opened.concat(panelId));
    }
  };

  const getPanelClasses = (panelId: number) => {
    return ['panel-collapse', 'collapse', ...(opened.includes(panelId) ? ['in'] : [])].join(' ');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="panel-group" id="accordion">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(1)} style={style}>
                  What do the icons mean?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(1)}>
              <div className="panel-body">
                <table className="table table-condensed">
                  <tbody>
                    <tr>
                      <td>
                        <img alt="ev charger icon" src={iconEV} width="21" height="21" />
                      </td>
                      <td>EV charging stations available</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="food icon" src={iconFood} width="21" height="21" />
                      </td>
                      <td>Food (typically found at service plazas)</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="fuel icon" src={iconFuel} width="21" height="21" />
                      </td>
                      <td>Fuel (typically found at service plazas)</td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          alt="overnight parking icon"
                          src={iconOvernight}
                          width="21"
                          height="21"
                        />
                      </td>
                      <td>Overnight parking is allowed</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="parking icon" src={iconParking} width="21" height="21" />
                      </td>
                      <td>Parking area</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="pet area icon" src={iconPet} width="21" height="21" />
                      </td>
                      <td>Pet areas</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="picnic table icon" src={iconPicnic} width="21" height="21" />
                      </td>
                      <td>Picnic tables available</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="playground icon" src={iconPlayground} width="21" height="21" />
                      </td>
                      <td>Children's playground available</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="restroom icon" src={iconRestrooms} width="21" height="21" />
                      </td>
                      <td>Men's and women's restrooms</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="rv dump icon" src={iconRV} width="21" height="21" />
                      </td>
                      <td>RV dump station</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="security icon" src={iconSecurity} width="21" height="21" />
                      </td>
                      <td>Security on-site</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="vending machine icon" src={iconVending} width="21" height="21" />
                      </td>
                      <td>Vending machines</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="water icon" src={iconWater} width="21" height="21" />
                      </td>
                      <td>Drinking water</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="welcome center icon" src={iconWelcome} width="21" height="21" />
                      </td>
                      <td>Rest stop is also a state welcome center</td>
                    </tr>
                    <tr>
                      <td>
                        <img alt="wifi icon" src={iconWifi} width="21" height="21" />
                      </td>
                      <td>Wireless internet</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(0)} style={style}>
                  What is the "Near Me" tab?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(0)}>
              <div className="panel-body">
                <p>
                  The "Near Me" tab will show you all of the rest stops within 25 miles of your
                  current location. The app does not know which direction you are heading in (yet)
                  so it will only show northbound rest stops north of you, southbound rest stops
                  south of you, etc.
                </p>
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(2)} style={style}>
                  Why is the GPS always on?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(2)}>
              <div className="panel-body">
                <p>
                  A new feature of this release is adding rest stops to a notification list. If
                  you've added a rest stop to this list (by clicking the bell icon) then the GPS
                  will stay on until you're within 5 miles of that rest stop.
                </p>
                <p>
                  If you'd like to turn the GPS off and still keep items in your notification list,
                  simpley double press the "home" button and swipe the USA Rest Stops app out of the
                  list of running apps. This will fully close the app and turn off the GPS.
                </p>
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(3)} style={style}>
                  What are favorites?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(3)}>
              <div className="panel-body">
                <p>
                  Adding a rest stop as a "favorite" can be helpful if you frequently drive the same
                  routes routinely. It will help you easily remember the nicer ones to spend the
                  night or that have dump stations for your RV.
                </p>
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(6)} style={style}>
                  What are the stars?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(6)}>
              <div className="panel-body">
                You are now able to rate rest stops! You can rate on a 5-star scale with 1 being a
                terrible place and 5 being an excellent rest stop. When the stars are gray it means
                the rest stop has no rating yet. When the stars are red, that is the average rating
                others have given the rest stop. When the starts are yellow, that is the rating you
                gave the rest stop. Note that this feature is only available when the app is in
                "real-time" mode.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(7)} style={style}>
                  Can I export to my navigation app?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(7)}>
              <div className="panel-body">
                Yes! You can export a rest stop to either Waze Navigation, Google Maps or Apple
                Maps. When you're viewing the rest stop details screen, press the "export" button in
                the upper right of the screen. This will pop up a menu allowing you to choose which
                navigation app to send to.
              </div>
            </div>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <button onClick={() => handleToggle(9)} style={style}>
                  How do I sort the list of rest stops?
                </button>
              </h4>
            </div>
            <div className={getPanelClasses(9)}>
              <div className="panel-body">
                First, you're now able to quickly switch between the two directions of the highway.
                Second, you can sort using the arrow buttons in the upper left. This will sort them
                north-to-sourth or south-to-north (for north/south highways) or east-to-west or
                west-to-east (for east/west highways).
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
