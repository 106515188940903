import logo from '../../images/logo.png';
import phone1 from '../../images/phone1.png';
import phone2 from '../../images/phone2.png';
import phone3 from '../../images/phone3.png';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-scroll';

SwiperCore.use([Navigation, EffectCoverflow]);

function Header() {
  const coverflowOptions = {
    depth: 120,
    modifier: 1,
    rotate: -30,
    slideShadows: false,
    stretch: 10
  };
  const navigationOptions = {
    nextEl: '.arrow-right',
    prevEl: '.arrow-left'
  };
  const breakpoints = {
    768: {
      slidesPerView: 3,
      spaceBetweenSlides: 5
    }
  };

  return (
    <header>
      <div id="intro" className="overlay-gradient">
        <nav className="navbar navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <div className="navbar-brand">
                <img className="img-responsive" src={logo} alt="Rest Stops logo" />
              </div>
            </div>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <Link to="home" smooth>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="features" smooth>
                    Features
                  </Link>
                </li>
                <li>
                  <Link to="gallery" smooth>
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="help" smooth>
                    Help
                  </Link>
                </li>
                <li className="download-btn">
                  <Link to="download" smooth>
                    Download <FontAwesomeIcon icon={faCloudDownloadAlt} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div id="home" className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headline">
                <h1 className="wow fadeInDown">USA Rest Stop Locator&trade;</h1>
                <p className="wow fadeInDown" data-wow-delay="0.5s">
                  Easily find all rest stops through the entire United States on your iOS or Android
                  device.
                </p>
              </div>
              <Swiper
                breakpoints={breakpoints}
                coverflowEffect={coverflowOptions}
                effect="coverflow"
                loop
                navigation={navigationOptions}
                slidesPerView={1}
                spaceBetween={10}
                speed={950}
              >
                <div className="arrow-left">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
                <div className="arrow-right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
                <SwiperSlide>
                  <img className="img-responsive" src={phone1} alt="phone1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={phone2} alt="phone2" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={phone3} alt="phone3" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
