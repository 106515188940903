import React from 'react';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faAndroid } from '@fortawesome/free-brands-svg-icons/faAndroid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phone3 from '../../images/phone3.png';
import ipad from '../../images/ipad.png';

function Download() {
  return (
    <section id="download" className="clearfix" data-scroll-index="2">
      <div className="download">
        <div className="overlay-silver bg" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="heading center">
                <h2>Download The App - even for ipad</h2>
                <p className="subheadline">
                  The same user interface across all of your devices helps make it simple and
                  intuitive to use
                </p>
              </div>
              <ul className="dw-btns">
                <li>
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Download for iOS"
                    href="https://itunes.apple.com/us/app/usa-rest-stops/id528988698?mt=8"
                  >
                    <FontAwesomeIcon icon={faApple} />
                  </a>
                </li>
                <li>
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Download for Android"
                    href="https://play.google.com/store/apps/details?id=com.insofttech.reststops"
                  >
                    <FontAwesomeIcon icon={faAndroid} />
                  </a>
                </li>
              </ul>
              <div className="tooltip-left wow fadeIn" data-wow-delay="2s">
                <div className="arrow" />
                <p>Very Fast App</p>
              </div>
              <div className="tooltip-right wow fadeIn" data-wow-delay="3s">
                <div className="arrow" />
                <p>Easy Setup</p>
              </div>
            </div>
          </div>
        </div>
        <img className="img-responsive first" src={ipad} alt="" />
        <img className="img-responsive second" src={phone3} alt="" />
      </div>
    </section>
  );
}

export default Download;
