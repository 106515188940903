import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import screen1 from '../../images/screen1.png';
import screen2 from '../../images/screen2.png';
import screen3 from '../../images/screen3.png';
import screen4 from '../../images/screen4.png';
import screen5 from '../../images/screen5.png';
import screen6 from '../../images/screen6.png';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';

SwiperCore.use([Navigation]);

function Gallery() {
  const navigationOptions = {
    nextEl: '.arrow-right',
    prevEl: '.arrow-left'
  };

  return (
    <section id="gallery" className="clearfix" data-scroll-index="3">
      <div className="gallery">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="heading center">
                <h2>Take a Look at our Gallery</h2>
                <p className="subheadline">Checkout the USA Rest Stops app in action!</p>
              </div>
            </div>
            <div className="col-md-12">
              <Swiper
                loop
                navigation={navigationOptions}
                slidesPerView={4}
                spaceBetween={5}
                speed={950}
              >
                <div className="arrow-left">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
                <div className="arrow-right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
                <SwiperSlide>
                  <img className="img-responsive" src={screen1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={screen2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={screen3} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={screen4} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={screen5} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-responsive" src={screen6} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
