import React from 'react';
import testi from '../../images/testi.jpg';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

SwiperCore.use([Navigation]);

function Testimonials() {
  const navigationOptions = {
    nextEl: '.arrow-right',
    prevEl: '.arrow-left'
  };

  return (
    <section id="testimonials" className="clearfix">
      <div className="testis">
        <div className="overlay-silver bg" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="heading center">
                <h2>Hundreds of Happy Customers!</h2>
                <p className="subheadline">
                  Take a look at what some of the reviews have to say about the app
                </p>
              </div>
              <Swiper loop navigation={navigationOptions} speed={950}>
                <div className="arrow-left">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </div>
                <div className="arrow-right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
                <SwiperSlide>
                  <div>
                    <blockquote>
                      <img className="img-responsive img-circle" src={testi} alt="" />
                      <cite>
                        Jordan D. <span>4 stars</span>
                      </cite>
                      <p>
                        Love this app. It's especially useful when traveling with two young kids and
                        allows us to plan stops as we go.
                      </p>
                    </blockquote>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <blockquote>
                      <img className="img-responsive img-circle" src={testi} alt="" />
                      <cite>
                        Chad M. <span>4 stars</span>
                      </cite>
                      <p>
                        Th is a great find as we prepare to drive across the country. Only thing to
                        improve - don't leave GPS on at all times - utilize Google location services
                        on the device to combine GPS, cellular, and Wi-Fi location. Let the user
                        decide if full on GPS is the best way. Yeah, an dI paid the remove ads fee
                        within the first 10 minutes because this app is so useful!
                      </p>
                    </blockquote>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <blockquote>
                      <img className="img-responsive img-circle" src={testi} alt="" />
                      <cite>
                        Amanda P. <span>5 stars</span>
                      </cite>
                      <p>
                        When travelling with a toddler, you realize how important potty breaks
                        become. This app is perfect for road trips! And I can let my big girl know
                        how long she has to 'hold it' for.
                      </p>
                    </blockquote>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
