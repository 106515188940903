import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';

function Features() {
  return (
    <section id="features" className="clearfix" data-scroll-index="1">
      <div className="get-started">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 heading center">
              <h2>Unique Features</h2>
              <p className="subheadline">
                Take a look at the features that will make your trip planning a lot easier
              </p>
            </div>
            <div className="col-sm-4">
              <div className="icon-wrap">
                <i className="icon">
                  <FontAwesomeIcon icon={faLocationArrow} />
                </i>
                <h3>Location Based</h3>
                <p>Find rest stops within 25 miles of your current location.</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="icon-wrap">
                <i className="icon">
                  <FontAwesomeIcon icon={faShieldAlt} />
                </i>
                <h3>All US Highways</h3>
                <p>
                  We've got you covered whether you're on an Interstate, US Highway or state road.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="icon-wrap">
                <i className="icon">
                  <FontAwesomeIcon icon={faTachometerAlt} />
                </i>
                <h3>Real-Time Status</h3>
                <p>
                  You're viewing the rest stop statuses as we get it in real-time. As soon as we
                  receive any data it's available in the app immediately.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="icon-wrap">
                <i className="icon">
                  <FontAwesomeIcon icon={faComment} />
                </i>
                <h3>Comments &amp; Ratings</h3>
                <p>Let your fellow travelers know how good (or bad) a particular rest stop is.</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="icon-wrap">
                <i className="icon">
                  <FontAwesomeIcon icon={faBell} />
                </i>
                <h3>Notifications</h3>
                <p>
                  Get notified when you get within 5 miles of a rest stop that you select, perfect
                  for trip planning!
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="icon-wrap">
                <i className="icon">
                  <FontAwesomeIcon icon={faHeart} />
                </i>
                <h3>Favorites</h3>
                <p>If you are a frequent traveler, save your favorite places to stop.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
